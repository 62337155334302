import React from 'react'
import { useEffect, useState } from 'react';

import Header from '../../components/Header/Header';
import Footer from '@/components/Footer/Footer';
import BreadCrumb from '../../components/BreadCrumb';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { getProductDetail, getCustomizeDetail, getExhibitionDetail, getCaseDetail, getNewsDetail, getCarouselDetail } from '../../api/index';

import style from './index.module.scss';


export default function Detail() {
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [cateId, setCateId] = useState(0);
  const { id } = useParams();
  let [searchParams] = useSearchParams();    
  const location = useLocation();
  
  useEffect(() => {
    let fuc = () => {};
    if(location.pathname.indexOf('product') > -1){
      fuc = getProductDetail;
    }else if(location.pathname.indexOf('news') > -1){
      fuc = getNewsDetail;
    }else if(location.pathname.indexOf('case') > -1){
      fuc = getCaseDetail;
    }else if(location.pathname.indexOf('exhibition') > -1){
      fuc = getExhibitionDetail;
    }else if(location.pathname.indexOf('customize') > -1){
      fuc = getCustomizeDetail;
    }
    if(searchParams.get('isCarousel') == 1){
      fuc = getCarouselDetail;
    }

    fuc(id).then(res => {
      setContent(res.data.content);
      setTitle(res.data.name || res.data.title);
      if(location.pathname.indexOf('product') > -1){
        setCateId(res.data.category_id)
      }else{
        setCateId(0);
      }
    })
    
  }, [])

  return (
    <div>
      <Header />
      <BreadCrumb title={title} cateId={cateId} />
      <div className={style.content} style={{ textAlign: "center" }}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      <Footer></Footer>
    </div>
  )
}
