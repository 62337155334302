import styles from './index.module.scss';
import { useEffect, useRef } from 'react';
import { navigateBlank } from '../../utils';



export default function BrandInformation({ title, text, img, link, content, isCarousel, setButtonPostion, cateId, type,id }) {
  const imgRef = useRef();
  function handleClick() {
    if (link) {
      window.open(link, 'blank')
    } else if (content) {
        navigateBlank(`/${type}/${id}?isCarousel=${isCarousel ? 1 : 0}`);
      if (cateId) {
        localStorage.setItem('cateId', cateId);
      } else {
        localStorage.removeItem('cateId');
      }
    }
  };

  function handleResize() {
    if (isCarousel) {
      setTimeout(() => {
        setButtonPostion(imgRef.current.height)
      }, 200)
    }
  }

  useEffect(() => {
    if (isCarousel) {
      setTimeout(() => {
        setButtonPostion(imgRef.current.height)
      }, 100)
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [isCarousel]);

  function handleLoad() {
    if (isCarousel) {
      setTimeout(() => {
        setButtonPostion(imgRef.current.height)
      }, 100)
    }
  }
  return (
    <div className={"column " + styles.brand_information}>
      <div className={styles.img_wraper}><img ref={imgRef} width="100%" src={img} onLoad={handleLoad} onClick={handleClick} /></div>
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.text_content + " text"}>
        {text}
      </p>
      <span className="link" onClick={handleClick}>了解详情</span>
    </div>
  )
}