import { useEffect, useRef } from 'react';
import { navigateBlank } from '../../utils';
import './index.scss';
import { throttle } from '../../utils';




export default function ProductionItem({ img, title, text, content, cateId, type, id }) {
  const imgRef = useRef();

  function handleClick(e) {
    e.stopPropagation();
    if (content) {
      navigateBlank(`/${type}/${id}`);
      if (cateId) {
        localStorage.setItem('cateId', cateId);
      } else {
        localStorage.removeItem('cateId');
      }
    }
  }

  const lazyLoad = throttle(function () {
    let clietH = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    // console.log(clietH, scrollTop);
  })


  useEffect(() => {
    // window.addEventListener("scroll", lazyLoad);
    return () => {
      // window.removeEventListener('scroll', lazyLoad)
    }
  }, [])


  return (
    <div className="product_item column">
      <img ref={imgRef} src={img} className='product_poster' onClick={handleClick} alt="" />

      <div className="product_info">
        <p className='title name'>{title}</p>
        <p className='cate'>{text}</p>
        <a className="link" onClick={handleClick}>立即了解</a>
      </div>
    </div>
  )
}