import './index.scss';

import SearchBar from '@/components/SearchBar/SearchBar';
import ProductionItem from '../../components/ProductionItem/ProductionItem';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import NoResult from '@/components/NoResult/NoResult';
import { getProducts, getProductCate } from '@/api';
import { usePageQuery } from '@/hooks';
import { Row, Col } from 'antd';
import { useEffect, useState } from 'react';



export default function List() {
  const { list, cateList, onSearchChange, setParams, params } = usePageQuery(getProducts, getProductCate);

  return (
    <div className="list_page">
      <Header />
      <SearchBar productTypes={cateList} onChange={onSearchChange} />

      <div className="product_list">
        <Row gutter={20}>
          {list.map(it => {
            return (
              <Col key={it.id} md={8} xs={24}>
                <ProductionItem
                  id={it.id}
                  type='product'
                  content={it.content}
                  title={it.name}
                  text={it.desc}
                  img={it.image}
                  cateId={it.category_id}
                />
              </Col>
            )
          })}

        </Row>
      </div>

      {list.length === 0 && <NoResult />}
      <Footer length={list.length} onReachBottom={() => {
        if (list.length === params.page * 12) {
          setParams({
            ...params,
            page: params.page + 1
          })
        }
      }} />
    </div>
  )
}