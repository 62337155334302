import './index.scss';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import RuiJiaCarousel from '@/components/RuiJiaCarousel/RuiJiaCarousel'
import SearchBar from '@/components/SearchBar/SearchBar';
import BrandInformation from '@/components/BrandInformation/BrandInformation';
import NoResult from '@/components/NoResult/NoResult';
import { Row, Col } from 'antd';
import { getExhibitionList, getExhibitionCate } from '@/api';
import { usePageQuery } from '@/hooks';




export default function CasePage() {
    const { list, cateList, onSearchChange, setParams, params } = usePageQuery(getExhibitionList, getExhibitionCate);
    return (
        <div className="case_page">
            <Header />
            <RuiJiaCarousel path="exhibition" />
            <SearchBar productTypes={cateList} onChange={onSearchChange} />
            {/* 品牌咨询 */}
            <div className="case_list">
                <Row gutter={40}>
                    {list?.map(it => {
                        return (
                            <Col key={it.id} className="column" md={12} xs={24}>
                                <BrandInformation
                                    id={it.id}
                                    type='exhibition'
                                    key={it.id}
                                    title={it.title}
                                    text={it.desc}
                                    img={it.image}
                                    content={it.content}

                                />
                            </Col>
                        )
                    })}

                </Row>
            </div>
            {list?.length === 0 && <NoResult />}
            <Footer length={list.length} onReachBottom={() => {
                if (list.length === params.page * 12) {
                    setParams({
                        ...params,
                        page: params.page + 1
                    })
                }
            }} />
        </div>
    )
}