import style from './index.module.scss';
import './index.scss';
import LogoPc from '@/assets/img/ic_logo_web.png';
import HambergerIcon from '@/assets/img/ic_mulu.png';
import PhoneIcon from '@/assets/img/ic_dianhua.png';
import MessageIcon from '@/assets/img/ic_lianxiwomen.png';
import SearchIcon from '@/assets/img/ic_sousuo.png';
import HambergerPhoneIcon from '@/assets/img/ic_mulu_phone.png';
import PhonePhoneIcon from '@/assets/img/ic_dianhua_phone.png';
import MessagePhoneIcon from '@/assets/img/ic_lianxiwomen_phone.png';
import SearchPhoneIcon from '@/assets/img/ic_sousuo_phone.png';
import HyridIcon from '@/assets/img/hyrid.jpg';
import ErrelabIcon from '@/assets/img/errelab.jpg';

import { useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';

import { useScrollToTopHook } from '@/hooks/index.js';

import Drawer from '@/components/Drawer/Drawer';
import Tree from '@/components/Tree/Tree';
import MenuFooter from './components/MenuFooter/MenuFooter';
import Alert from '@/components/Alert/Alert';
import LinkUs from '@/components/LinkUs/LinkUs';
import NeedHelpContent from '@/components/NeedHelpContent/NeedHelpContent';

import { getProductCate } from '@/api'
import { mapMenuData } from '@/utils';


const initMenuData = [
    {
        name: '品牌', children: [
            {
                name: '品牌介绍',
                link: '/brand-introduce'
            },
            {
                name: '工厂介绍',
                link: '/factory-introduce'
            }
        ]
    },
    {
        name: '产品',
        children: []
    },
    {
        name: '定制方案', children: [
            {
                name: '瑞加思维2.0',
                link: '/customize'
            },
            {
                name: '瑞加专业级品牌',
                link: '/customize'
            },
            {
                name: '瑞加定制服务',
                link: '/customize'
            }
        ]
    },
    {
        name: '案例展示', children: [
            {
                name: '国内案例',
                link: '/case?id=3'
            },
            {
                name: '国外案例',
                link: '/case?id=4'
            }
        ]
    },
    { name: '展厅效果', link: '/exhibition' },
    {
        name: '企业资讯', children: [
            {
                name: '艺术风向',
                link: '/information?id=1'
            },
            {
                name: '品牌咨询',
                link: '/information?id=3'
            },
        ]
    },
    { name: '联系我们', href: '#contactUs' },
]


export default function Header() {
    const navigate = useNavigate();
    useScrollToTopHook()

    const [open, setOpen] = useState(false);
    const [linkOpen, setLinkOpen] = useState(false)
    const [helpOpen, setHelpOpen] = useState(false)
    const [menuData, setMenuData] = useState(initMenuData);


    const alertRef = useRef(null);

    useEffect(() => {
        getProductCate().then(res => {
            const productMenu = mapMenuData(res.data);
            const newData = [...menuData];
            newData[1].children = productMenu
            setMenuData(newData)
        })
    }, [])


    const handleNavigate = (e) => {
        const { path } = e.target.dataset;
        if (path.startsWith('http')) {
            window.open(path)
        } else {
            navigate(path);
        }
    }

    const openNav = () => {
        setOpen(true)
    }
    const openLinkDrawer = () => {
        setLinkOpen(true);
        setOpen(false);
    }
    const openHelpDrawer = () => {
        setHelpOpen(true);
        setOpen(false);
    }


    return (
        <header className={style.header + " between rj_header"}>
            <Alert ref={alertRef} />
            <Drawer open={open} onClose={() => setOpen(false)} title="目录">
                <Tree data={menuData} clickItem={function () {
                    setOpen(false)
                }} />
                <MenuFooter closeDrawer={() => setOpen(false)} openLinkDrawer={openLinkDrawer} openHelpDrawer={openHelpDrawer} />
            </Drawer>
            <Drawer open={linkOpen} onClose={() => setLinkOpen(false)} title="联系我们" placement="right">
                <LinkUs />
            </Drawer>
            <Drawer open={helpOpen} onClose={() => setHelpOpen(false)} title="需要帮助" placement="right">
                <NeedHelpContent />
            </Drawer>
            <img src={HambergerIcon} className={style.icon + ' hidden-xs'} alt="导航" onClick={openNav} />
            <img src={HambergerPhoneIcon} className={style.icon + ' visible-xs'} alt="导航" onClick={openNav} />

            <img data-path='/search' onClick={handleNavigate} src={SearchPhoneIcon} alt="" className={style.icon + ' visible-xs'} />
            <img src={LogoPc} className={style.logo} data-path='/' alt="logo" onClick={handleNavigate} />
            <ul className={"between " + style.right_nav}>
                <li className='hidden-xs'>
                    <img data-path='https://hyrid.eu' onClick={handleNavigate} src={HyridIcon} alt="" className={style.icon} />
                </li>
                <li className='hidden-xs'>
                    <img data-path='https://www.errelab.com' onClick={handleNavigate} src={ErrelabIcon} alt="" className={style.icon2} />
                </li>
                <li className='hidden-xs'>
                    <img data-path='/search' onClick={handleNavigate} src={SearchIcon} alt="" className={style.icon} />
                </li>
                <li>
                    <img src={MessageIcon} alt="" className={style.icon + ' hidden-xs'} onClick={openHelpDrawer} />
                    <img src={MessagePhoneIcon} alt="" className={style.icon + ' visible-xs'} onClick={openHelpDrawer} />
                </li>
                <li>
                    <img src={PhoneIcon} alt="" className={style.icon + ' hidden-xs'} onClick={openLinkDrawer} />
                    <img src={PhonePhoneIcon} alt="" className={style.icon + ' visible-xs'} onClick={openLinkDrawer} />
                </li>
            </ul>
        </header>
    )
}