import { useState, useEffect } from 'react'
import { Breadcrumb } from 'antd';

import { getProductCate } from '../../api';

import styles from './index.module.css';


const breadName = {
  customize: {
    name: '定制方案',
    path: '/customize'
  },
  case: {
    name: '案例展示',
    path: '/case'
  },
  exhibition: {
    name: '展厅效果',
    path: '/exhibition'
  },
  information: {
    name: '企业资讯',
    path: '/information'
  }
}
export default function BreadCmp({title,cateId}) {
  const [path, setPath] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    getProductCate().then(res => {
      if (cateId) {
        const cate = res.data.find(it => it.id == cateId)
        setName(cate.name);
      }
    })
  }, [cateId])

  useEffect(() => {
    let path = localStorage.getItem('path');
    if(!path){
      path = '/';
    }
    setPath(path);
    if (location.pathname.indexOf('product') > -1 && cateId) {
      return;
    }
    if (path === '/') {
      setName('首页');
    } else {
      setName(breadName[path.slice(1)]?.name);
    }
  }, [location.pathname]);

  return (
    <div className={styles.bread_cmp}>
      <span>当前位置：</span>
      <Breadcrumb className={styles.ant_bread_cmp}>
        <Breadcrumb.Item href={path}>{name}</Breadcrumb.Item>
        <Breadcrumb.Item>{title}</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  )
}
