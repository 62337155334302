import './index.scss';
import prevIcon from '@/assets/img/ic_qiehuan_zuo.png';
import nextIcon from '@/assets/img/ic_qiehuan_you.png';
import prevIconPhone from '@/assets/img/ic_qiehuan_zuo_phone.png';
import nextIconPhone from '@/assets/img/ic_qiehuan_you_phone.png';
import BrandInformation from '@/components/BrandInformation/BrandInformation';
import { useRef, useEffect, useState } from 'react';
import { Carousel } from 'antd';
import { getCarousel } from '@/api';



export default function RuiJiaCarousel({ path }) {
    const carouselRef = useRef(null)
    const [list, setList] = useState([]);
    const [btnTop, setBtnTop] = useState(0);

    function handlePrev() {
        carouselRef.current.prev()
    }
    function handleNext() {
        carouselRef.current.next()
    }

    useEffect(() => {
        getCarousel({ show_location: path }).then(res => {
            setList(res.data);
        })
    }, [path])

    return (
        list.length > 0 ?
            <div className="carousel-wrap">
                <img src={prevIconPhone} style={{ top: btnTop + 'px' }} className="switch_btn prev visible-xs visible-sm" onClick={handlePrev} alt="" />
                <img src={nextIconPhone} style={{ top: btnTop + 'px' }} className="switch_btn next visible-xs visible-sm" onClick={handleNext} alt="" />
                <img src={prevIcon} style={{ top: btnTop + 'px' }} className="switch_btn prev hidden-sm" onClick={handlePrev} alt="" />
                <img src={nextIcon} style={{ top: btnTop + 'px' }} className="switch_btn next hidden-sm" onClick={handleNext} alt="" />
                <Carousel ref={carouselRef} dots={false}>
                    {list.map(it => {
                        return (<BrandInformation
                            id={it.id}
                            type={path == 'home' ? 'product' : path === 'cases' ? 'case' : path}
                            isCarousel={true}
                            key={it.id}
                            img={it.image}
                            text={it.desc}
                            content={it.content}
                            link={it.jump_url}
                            title={it.title}
                            setButtonPostion={h => {
                                setBtnTop(h / 2)
                            }}
                        />)
                    })}

                </Carousel>
            </div>
            :
            null
    )
}