import styles from './index.module.scss';
import { navigateBlank } from '../../utils';

export default function ProductIntrodution({ title, text, img, right, content, cateId, type, id }) {

  function handleClick() {
    if (content) {
      navigateBlank(`/${type}/${id}`);
      if (cateId) {
        localStorage.setItem('cateId', cateId);
      } else {
        localStorage.removeItem('cateId');
      }
    }
  }
  return (
    <div className={"row " + styles.product_introdution}>
      {<div className={"col-sm-6 " + styles.left + ' ' + styles.product_introdution_img + (!right ? '' : ' hidden')}>
        <img src={img} width="100%" alt="" onClick={handleClick} />
      </div>}
      <div className={"col-sm-6 " + styles.product_introdution_info}>
        <div className={"column " + styles.product_introdution_content_block}>
          <h4 className='title' style={{ display: 'flex' }}>主推产品 <span className='visible-xs'>{title}</span></h4>
          <h4 className='title hidden-xs'>{title}</h4>
          <p className="text">{text}</p>
          <span className="link" onClick={handleClick}>查看详情</span>
        </div>
      </div>
      {<div className={"col-sm-6 " + styles.right + ' ' + styles.product_introdution_img + (right ? ' ' : ' hidden')}>
        <img src={img} width="100%" alt="" onClick={handleClick} />
      </div>}
    </div>
  )
}