import { useRoutes } from 'react-router-dom';
import { Suspense, useEffect } from 'react';


import Home from '../views/Home/index'
import List from '../views/List/index'
import Search from '../views/Search/index'
import Case from '../views/Case/index'
import Production from '../views/Production/index'
import Exhibition from '../views/Exhibition/index'
import Information from '../views/Information/index'
import Customize from '../views/Customize/index'
import Address from '../views/Address/index'
import Question from '../views/Question/index'
import Introduce from '../views/Introduce';
import Detail from '../views/Detail/Detail'


const routes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/list',
    element: <List />
  },
  {
    path: '/search',
    element: <Search />
  },
  {
    path: '/case',
    element: <Case />
  },
  {
    path: '/production',
    element: <Production />
  },
  {
    path: '/exhibition',
    element: <Exhibition />
  },
  {
    path: '/information',
    element: <Information />
  },
  {
    path: '/customize',
    element: <Customize />
  },
  {
    path: '/address',
    element: <Address />
  },
  {
    path: '/question',
    element: <Question />
  },
  {
    path: '/brand-introduce',
    element: <Introduce />
  },
  {
    path: '/factory-introduce',
    element: <Introduce />
  },
  {
    path: '/law-term',
    element: <Introduce />
  },
  {
    path: '/law-statement',
    element: <Introduce />
  },
  {
    path: '/product/:id', //产品
    element: <Detail />
  },
  {
    path: '/news/:id',//咨询
    element: <Detail />
  },
  {
    path: '/case/:id',//案例
    element: <Detail />
  },
  {
    path: '/exhibition/:id', //展厅
    element: <Detail />
  },
  {
    path: '/customize/:id', //定制方案
    element: <Detail />
  },
]
const Router = () => {
  const reg = /^\/(case|exhibition|product|news|customize)\/\d+$/g;
  if(!reg.test(location.pathname)){
    localStorage.setItem('path',location.pathname + location.search)
  }

  return (
    <Suspense>
      {useRoutes(routes)}
    </Suspense>
  )
}


const RouterBeforeEach = ({ children }) => {

  return children
};

export { Router, RouterBeforeEach }