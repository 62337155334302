import styles from './index.module.scss';
import './index.scss';
import playIcon from '@/assets/img/ic_bofang.png';
import pauseIcon from '@/assets/img/ic_zanting.png';
import ProductIntrodution from '@/components/ProductIntrodution/ProductIntrodution';
import BrandInformation from '@/components/BrandInformation/BrandInformation';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import RuiJiaCarousel from '@/components/RuiJiaCarousel/RuiJiaCarousel'
import { useRef, useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { getConfig, getProducts, getNewsList, getCaseList, getExhibitionList } from '@/api';

export default function Home() {
  const ref = useRef(null)
  const [products, setProducts] = useState([]);
  const [news, setNews] = useState([]);
  const [cases, setCases] = useState([]);
  const [exhibitions, setExhibitions] = useState([]);
  const [video, setVideo] = useState('');
  const [poster, setPoster] = useState('');


  useEffect(() => {
    getConfig().then(res => {
      res.data.map(it => {
        if (it.name === "promotional_video") {
          setVideo(it.value);
        } else if (it.name === "promotional_video_cover") {
          setPoster(it.value);
        }
        return 0
      })
    });

    getProducts({ home_page_show: 1 }).then(res => {
      setProducts(res.data.slice(0, 4))
    });

    getNewsList({ home_page_show: 1 }).then(res => {
      setNews(res.data.slice(0, 2))
    });

    getCaseList({ home_page_show: 1 }).then(res => {
      setCases(res.data.slice(0, 2))
    });

    getExhibitionList({ home_page_show: 1 }).then(res => {
      setExhibitions(res.data.slice(0, 2))
    });
  }, [])

  // useEffect(() => {
  //   ref.current?.play();
  // },[ref.current])


  return (
    <div className="home-page">
      <Header />

      {video && (<div className='video_introdution'>
        {/* <img onClick={handleTrigger} className={'play_icon'} src={isPlay ? pauseIcon : playIcon} alt="" /> */}
        <video
          controls
          muted
          loop
          ref={ref}
          className={styles.video}
          src={video}
          autoPlay
          poster={poster}
        >
          <p>你的浏览器不支持 HTML5 视频。</p>
        </video>
      </div>)}

      {/* 主推产品 */}
      {products.length ? <div className='product-introdution-wrap'>
        {products.map((prod, i) => {
          return (
            <ProductIntrodution
              type='product'
              key={prod.id}
              id={prod.id}
              right={i % 2 === 1}
              title={prod.name}
              img={prod.image}
              text={prod.desc}
              content={prod.content}
              cateId={prod.category_id}
            />
          )
        })
        }
      </div> : null
      }


      {/* 品牌咨询 */}
      {news.length ? (<Row className={"brand-information-wrap row"} gutter={{
        sm: 20
      }}>
        {
          news.map(it => {
            return (
              <Col className="column" key={it.id} md={12} xs={24}>
                <BrandInformation
                  type='news'
                  id={it.id}
                  img={it.image}
                  content={it.content}
                  title={it.name}
                  text={it.desc}
                />
              </Col>
            )
          })
        }

      </Row>
      ) : null}

      {/* 案例 */}

      {cases.length ? (
        <Row gutter={20} className='home-list'>
          {cases.map(it => {
            return (
              <Col key={it.id} className="column" md={12} xs={24}>
                <BrandInformation
                  key={it.id}
                  type='case'
                  id={it.id}
                  content={it.content}
                  title={it.title}
                  text={it.desc}
                  img={it.image}
                />
              </Col>
            )
          })}
        </Row>
      ) : null}


      {/* 展厅效果 */}
      {exhibitions.length ? (
        <Row gutter={20} className='home-list'>
          {exhibitions.map(it => {
            return (
              <Col key={it.id} className="column" md={12} xs={24}>
                <BrandInformation
                  type='exhibition'
                  id={it.id}
                  key={it.id}
                  title={it.title}
                  text={it.desc}
                  img={it.image}
                  content={it.content}

                />
              </Col>
            )
          })}
        </Row>
      ) : null}


      {/* 轮播 */}
      <RuiJiaCarousel path='home' />

      <Footer />
    </div>
  )
}